import PricingTable from './PricingTable';

export default class PotioExperiment extends HTMLElement {
  constructor() {
    super(); // Always call super first in constructor
  }

  // Called when element is added to DOM
  connectedCallback() {
    const table = new PricingTable();
    Array.from(this.attributes).forEach(attr => table.setAttribute(attr.name, attr.value));
    table.setAttribute('experiment', 'yes');
    this.insertAdjacentElement('afterend', table);
  }

  disconnectedCallback() {
    
  }

}

// Define the new element
customElements.define('potio-experiment', PotioExperiment);

module.exports = PotioExperiment;