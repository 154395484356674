import { iframeResizer } from 'iframe-resizer';
const pricingUri = process.env.PRICING_URI;

let canSkipToCheckoutResolve = () => {};
window.potio = {
  canSkipToCheckout: new Promise(reso => canSkipToCheckoutResolve = reso),
};

export default class PotioPricingTable extends HTMLElement {
  constructor() {
    super(); // Always call super first in constructor
    this.uid = Math.random().toString(36).slice(-6);
    // Attach a shadow root to the element.
    this.attachShadow({mode: 'open'});
    // Bind the handleMessage function to we can later unbind it
    this.handleMessage = this.handleMessage.bind(this);
  }

  // Called when element is added to DOM
  connectedCallback() {
    if (this.getAttribute('data-key') && !this.getAttribute('key')) {
      this.setAttribute('key', this.getAttribute('data-key'));
    }
    if (!this.getAttribute('key')) {
      throw Error('Key is not defined');
    }
    this.action = this.getAttribute('action');
    if (this.action !== 'signup' && this.action !== 'payment' && this.action !== 'hidden') {
      throw Error('Action must be either signup or payment');
    }
    if (this.action === 'hidden') {
      this.style.display = 'none';
    }
    // Called when the element is inserted into a document, including into a shadow tree
    this.render();
    this.initIframeResizer();
    window.addEventListener('message', this.handleMessage, false);
  }

  disconnectedCallback() {
    window.removeEventListener('message', this.handleMessage, false);
  }

  getIframe() {
    return this.shadowRoot.querySelector(`#potio-iframe-${this.uid}`);
  }

  getURL() {
    const props = [];
    const propObject = {
      action: this.action,
      client_reference_id: this.getAttribute('client-reference-id') || this.getAttribute('client_reference_id'),
      customer_email: this.getAttribute('customer-email') || this.getAttribute('customer_email'),
      customer: this.getAttribute('customer'),
      fetch: this.getAttribute('fetch'),
      experiment: this.getAttribute('experiment'),
      parentUri: window.location.href,
    };
    Object.keys(propObject).forEach(prop => {
      const value = propObject[prop];
      if (value) {
        props.push(`${prop}=${value}`);
      }
    });
    // Iterate all metadata attributes
    for (const {name, value} of this.attributes) {
      if (name.startsWith('metadata-')) {
        props.push(`${name}=${value}`);
      }
    }
    const key = this.getAttribute('key');
    return `${pricingUri}/${key}/#${props.join('&')}`;
  }

  render() {
    this.shadowRoot.innerHTML = `
      <style>
        :host {
          display: block;
          border: none;
        }
        body {
          margin: 0;
        }
        iframe {
          width: 100%;
          height: 100%; /* You can make this dynamic */
          border: none;
        }
      </style>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.9/iframeResizer.min.js"></script>
      <iframe id="potio-iframe-${this.uid}" src="${this.getURL()}" frameborder="0"></iframe>
    `;
  }

  initIframeResizer() {
    // Use setTimeout to wait for the DOM to be updated
    setTimeout(() => {
      const iframe = this.getIframe();
      iframeResizer({log: false}, iframe);
    }, 0);
  }

  handleMessage(ev) {
    const {type, options} = ev.data;
    switch (type) {
      case 'navigate':
        const {url} = options;
        window.location.href = url; // Navigate to the URL
        break;
      case 'ready':
        this.dispatchEvent(new CustomEvent('ready'));
        break;
      case 'cookies':
        const {cookies} = options;
        const price = cookies['potio-pricing-table-signup-price'];
        const timestamp = cookies['potio-pricing-table-signup-timestamp'];
        if (!price || !timestamp) {
          canSkipToCheckoutResolve({canSkip: false});
        } else {
          const currentTime = Math.floor(Date.now() / 1000);
          const signupTime = parseInt(timestamp);
          if (currentTime - signupTime <= 600) {
            canSkipToCheckoutResolve({canSkip: true, checkout: ({returnUrl}) => this._checkoutPrice(price, returnUrl)});
          } else {
            canSkipToCheckoutResolve({canSkip: false});
          }
        }
        // canSkipToCheckoutResolve = () => {};
        break;
      case 'analytics_event':
        const {event} = options;
        delete options.event;
        this.dispatchEvent(new CustomEvent(event, {detail: options}));
        break;
      default:
        break;
    }
  }

  _checkoutPrice(priceId, returnUrl) {
    this._sendData({checkoutPrice: priceId, returnUrl});
  }

  _sendData(data) {
    const iframe = this.getIframe();
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(data, '*');
    }
  }
}

// Define the new element
customElements.define('potio-pricing-table', PotioPricingTable);